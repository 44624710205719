@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    @font-face {
        font-family: "Nissan_Light";
        src: url(../public/fonts/NissanLight.eot);
        src: url(../public/fonts/NissanLight.eot?#iefix) format("embedded-opentype"), url(../public/fonts/NissanLight.woff) format("woff"), url(../public/fonts/NissanLight.ttf) format("truetype"), url(../public/fonts/NissanLight.svg#nissanproreg-webfont) format("svg");
        font-weight: 400;
        font-style: normal;
      }
      
      @font-face {
        font-family: "Nissan_Bold";
        src: url(../public/fonts/NissanBold.eot);
        src: url(../public/fonts/NissanBold.eot?#iefix) format("embedded-opentype"), url(../public/fonts/NissanBold.woff) format("woff"), url(../public/fonts/NissanBold.ttf) format("truetype"), url(../public/fonts/NissanBold.svg#nissanproreg-webfont) format("svg");
        font-weight: 400;
        font-style: normal;
      }
      
      @font-face {
        font-family: "Nissan_Regular";
        src: url(../public/fonts/NissanRegular.eot);
        src: url(../public/fonts/NissanRegular.eot?#iefix) format("embedded-opentype"), url(../public/fonts/NissanRegular.woff) format("woff"), url(../public/fonts/NissanRegular.ttf) format("truetype"), url(../public/fonts/NissanRegular.svg#nissanproreg-webfont) format("svg");
        font-weight: 400;
        font-style: normal;
      }
    
    h1 { @apply text-4xl; }
    body { font-family: "Nissan_Regular";}

}

@layer components {
    
  
    .app-container { @apply flex flex-col gap-0 mx-auto lg:flex-row xl:px-5 max-w-[1200px]; }
    

    /* VEHICLE DATA */
    .vehicle-data { @apply flex flex-col w-full lg:w-[500px] gap-2 lg:gap-6 p-6; }
    .vehicle-data img { @apply w-full; }
    .vehicle-data h2 { @apply mt-4 text-4xl font-bold; }
    .vehicle-data h2 span { @apply relative text-2xl font-thin -top-2; }
    .vehicle-data h2 small { @apply block text-xl font-thin; }
    .vehicle-data h3 { @apply text-2xl font-bold leading-6; }
    .vehicle-data h3 small { @apply block text-sm font-thin; }
    .vehicle-data div { @apply flex flex-row items-end justify-between; }
    .vehicle-data p { @apply text-xs font-thin; }
    

    /* CALCULATOR */
    .calculator { @apply grid w-full gap-6 p-6 overflow-hidden lg:py-8 lg:gap-8 lg:grid-cols-12 grow; }

    .field { @apply flex flex-col; }
    .field input, .field select { @apply order-1 w-full h-12 px-3 text-lg bg-white border outline-none border-gray placeholder:text-gray; }
    .field .input-error { @apply w-full h-12 px-3 text-lg bg-white border outline-none border-brand placeholder:text-gray; }
    .field input:focus + label, .field select:focus + label { @apply text-brand; }
    .field label { @apply mb-2 text-lg text-black transition-colors duration-100; }
    .field small { @apply order-3 my-2 text-xs text-brand; }

    .disclaimer {  @apply w-full col-span-12 p-4 text-xs leading-5 lg:p-8 bg-light-gray text-black/50; }


    /* MODALS */
    .modal {@apply fixed top-0 left-0 w-screen h-screen z-[998] bg-white/75 flex flex-col items-center justify-center invisible opacity-0 transition-all duration-300 ease-out origin-center; }
    .modal.open-modal {@apply visible opacity-100; }
    .modal.open-modal .modal-content {@apply visible translate-y-0; }
    
    .modal-content { @apply flex flex-row max-w-2xl w-full gap-6 bg-white drop-shadow-2xl relative z-[999] translate-y-10 transition-all duration-500 ease-out origin-center h-screen lg:h-auto overflow-y-scroll; }
    .modal-wrapper { @apply grid w-full grid-cols-12 gap-6; }
    
    .modal-body, .modal-header, .modal-footer { @apply flex w-full p-8; }

    .modal-header { @apply flex-row items-center justify-between px-8 py-4 border-b border-light-gray; }
    .modal-header h6 { @apply text-xl leading-[60px]; }
    .modal-header button { @apply w-12 transition-colors cursor-pointer bg-light-gray aspect-square hover:bg-gray hover:text-white; }

    .modal-body { @apply flex flex-col gap-10; }
    .modal-body a { @apply font-bold text-black; }
    .modal-body label { @apply z-10 flex flex-row gap-4 text-base cursor-pointer; }
    .modal-body label input { @apply box-content relative w-4 h-4 bg-transparent border appearance-none cursor-pointer border-gray aspect-square; }
    .modal-body label input::after { @apply w-4 h-4 bg-brand box-content content-[''] absolute scale-0 transition-all duration-100 origin-center ease-out; }
    .modal-body label p { @apply -my-1 leading-6; }
    .modal-body label input:checked { @apply w-4 h-4 border appearance-none border-brand aspect-square; }
    .modal-body label input:checked::after { @apply scale-100; }

    .modal-footer { @apply grid-cols-12 border-t border-light-gray; }

    
    .btn { @apply flex flex-row items-center justify-center w-full h-12 px-8 text-sm text-white uppercase border-0 bg-brand lg:max-w-[150px]; }
    .btn.alt { @apply text-black bg-gray lg:max-w-[300px]; }
    .btn.single { @apply w-full max-w-[100%] lg:max-w-[300px]; }
    .btn.single.disabled { @apply cursor-not-allowed bg-black/50; }
    .btn img { @apply w-[30px] rotate-180;}

    
}